import { createRoute } from '@tanstack/react-router';
import { HomePage } from './home.page';
import { homeSearchSchema } from './home.schema';
import { appRoute } from 'app/app.route';


export const  homeRoute = createRoute({
  getParentRoute: () => appRoute,
  path: '$key',
  component: HomePage,
  validateSearch: (search: any) => {
    return homeSearchSchema.parse(search);}
})