import { useQuery } from '@tanstack/react-query';
import { useParams, useSearch } from '@tanstack/react-router';
import { Error } from 'components/Error';
import { Loading } from 'components/Loading';
import { checkKeyAPI, loginAPI } from './home.api';
import { homeRoute } from './home.route';
import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CallIcon from '@mui/icons-material/Call';
import Box from '@mui/material/Box';
import { secondsToHms } from 'util/sec-to-hms';
import CallEndIcon from '@mui/icons-material/CallEnd';
import css from './home.module.css';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import audio from '../../ringing.mp3';
import { logger } from 'util/logger';
import { Autocomplete, Grid, TextField } from '@mui/material';
import {
  getCountries,
  getPhoneCode,
  isValidPhoneNumber,
} from 'libphonenumber-js';

import uuid4 from "uuid4";
import { roomClient } from "lib/agora"
import {
  QRTCParams,
} from "qrtc_web_sdk/RoomParams";

import { io } from "socket.io-client";
const socket = io("https://rtm-public.callpro.mn");

type Step = 'number' | 'extension' | 'ringing' | 'answer';

const userAudioDom = new Audio(audio);

const codes = [
  ...new Set(getCountries().map((country) => `+${getPhoneCode(country)}`)),
].sort();

export function HomePage() {
  const [seconds, setSeconds] = useState(0);
  const [step, setStep] = useState<Step>('number');
  const [number, setNumber] = useState('');
  const [socketError, setSocketError] = useState(false);
  const [shake, setShake] = useState(true);
  const [dot, setDot] = useState('   ');
  const [isPlayAudio, setPlayAudio] = useState(false);
  const [isPlayRing, setPlayRing] = useState(false);
  const [code, setCode] = useState('+976');
  const [cancel, setCancel] = useState(false);
  const [channelName, setChannelName] = useState('');
  const [channelHash, setChannelHash] = useState('');
  const natsTopic = useRef('');
  // const [token, setToken] = useState('');
  
  const username = number;

  const { type: isNumberDisabled } = useSearch({ from: homeRoute.id });


  const { key } = useParams({ from: homeRoute.id });
  

  const checkQuery = useQuery({
    queryKey: ['check-key', key],
    queryFn: () => checkKeyAPI(key),
    enabled: Boolean(key),
    retry: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retryOnMount: false,
    onSuccess(data) {
      logger({ from: 'CHECK QUERY', data });
    },
  });
  const loginQuery = useQuery({
    queryKey: ['login', key],
    queryFn: () =>
      loginAPI(
        checkQuery.data?.data?.data?.ext_name,
        checkQuery.data?.data?.data?.ext_password
      ),
    enabled: Boolean(checkQuery.data),
    retry: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retryOnMount: false,
    onSuccess(data) {
      logger({ from: 'LOGIN QUERY', data });
    },
    onError(e){
      logger({error: e, dataL: "err"})
    }
  });
  
  useEffect(() => {
    logger({ from: 'STEP', step });

    if (step === 'answer') {
      const secondsInterval = setInterval(() => {
        setSeconds((second) => second + 1);
      }, 1000);

      return () => clearInterval(secondsInterval);
    }

    if (step === 'ringing') {
      const shakeInterval = setInterval(() => {
        setShake((shake) => !shake);
      }, 1000);

      const dotInterval = setInterval(() => {
        setDot((dot) => {
          if (dot === '   ') return '.  ';
          else if (dot === '.  ') return '.. ';
          else if (dot === '.. ') return '...';
          else return '   ';
        });
      }, 500);

      return () => {
        clearInterval(shakeInterval);
        clearInterval(dotInterval);
      };
    }
  }, [step]);

  // useEffect(() => {
  //   const audio2 = new Audio(audio);

  //   audio2.loop = true;
  //   console.log("Za duyydaj bna ");
  //   isPlayRing ? audio2.play() : audio2.pause();
  // }, [isPlayRing]);

  useEffect(() => {
    isPlayAudio ? userAudioDom.play() : userAudioDom.pause();
  }, [isPlayAudio]);

  useEffect(() => {
    socket.on('connect', () => {
      logger({ from: 'SOCKET CONNECTION', socket });
    });

    socket.on('disconnect', () => {
      logger({ from: 'SOCKET DISCONNECT', socket });
    });

    socket.on('connect_error', (error) => {
      setSocketError(true);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
    };
  }, []);

  useEffect(() => {
    socket.on('message', (data: any) => {
      logger({ from: 'SOCKET MESSAGE', data });
      const roomData = JSON.parse(data.message).data;
      if (roomData?.method === 'hangup') {
        logger({ type: 'bye2', channelName });
        onEndCall();
      }  else if (roomData?.method === 'ringing') {
        setStep('ringing');
        setPlayRing(true);
      } else if (roomData?.method === 'answered') {
        setStep('answer');
        setPlayRing(false);
      }else if(roomData?.method === 'call_init'){
        const roomInfo: QRTCParams = {
          serverUrl: roomData?.serverUrl,
          sdkAppId: roomData?.appId,
          userSig: "",
          userId: roomData?.userId,
          userName: number,
          roomId: roomData?.roomId,
          maxRetryTime: 10*1000,
          xConferenceToken: roomData.rtcToken
        }
        roomClient.enterRoom(roomInfo);
      }
    });

    return () => {
      socket.off('message');
    };
  }, [channelName]);

  useEffect(() => {

    roomClient.on( "remoteUserEnterRoom", ( userInfo: any ) => {
      console.log("GG remoteUserEnterRoom: ");
     } );

    roomClient.on( "remoteUserLeaveRoom", ( userInfo: any ) => { 
      console.log("remoteUserLeaveRoom: ");
    } );
    roomClient.on( "enterRoom", async () => { 
      await roomClient.startLocalAudio(false);
    });

    roomClient.on( "error", (data) => {
      console.log("ERROR: ", data);
     } );
    roomClient.on("userAudioAvailable", async (audioInfo) => {
      console.log(
          `[userAudioAvailable] audioInfo=${JSON.stringify(audioInfo)}`
      );
      const { userId, available } = audioInfo;
      if (available && userAudioDom) {
        console.log("DOM available: ", available);
        console.log("userAudio.current: ", userAudioDom);
       
        await roomClient.startRemoteAudio(
            userId,
            // @ts-ignore:
            userAudioDom
        )
          
      } else {
          //需要刷新一下对应的video控件
      }
  });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomClient]);

  function onNumber(event: ChangeEvent<HTMLInputElement>) {
    const number = event.target.value;

    if (isNaN(Number(number))) return;

    const blacklists = ['9', '8', '7', '6', undefined];

    if (!blacklists.includes(number[0]) && code === '+976') return;

    setNumber(number);
  }

  function sendSocketMessage(message: any, room: any, username: any){
    socket.emit("sendMessage", {room, username, message});
    // console.log(`[You]: ${message} ${room} ${username}`);
  }

  function onNext() {
    setStep('extension'); 
  }

  function onStartCall(shortcut: number) {
    setStep('ringing');
    try {
      try {
        natsTopic.current =  `click.click.outbound.${uuid4()}`;
        const msg =  {
          destination: shortcut,
          // fromNumber: loginQuery.data?.result?.number,
          fromNumber: number,
          fromName: number,
          video: "false",
          roomId: natsTopic.current,
          callType: "audio",
          fromPrefix: checkQuery?.data?.data?.data?.fromPrefix,
          destPrefix: checkQuery?.data?.data?.data?.destPrefix
        }
        
        const room = "click.master.outbound";
        socket.emit("joinRoom", {room, username});
        sendSocketMessage(JSON.stringify(msg), 'click.master.outbound', username);
        socket.emit("joinRoom", {room: natsTopic.current, username});


      } catch (error) {
        console.log("Connection error: ");
      }
    } catch (error) {
      setSocketError(true);
    }
  }

  async function onEndCall() {
    logger({ type: 'end', channelName, channelHash });

    setStep('number');
    setSeconds(0);
    // setPlayRing(false);
    // setPlayAudio(false);
    setChannelName('');
    setChannelHash('');
    await onEndBeforeCall();
  }

  function onCode(code: string) {
    setCode(code);
  }

  async function onEndBeforeCall() {
    setStep('number');
    setCancel(true);
    const msg = {
      data: {
        method: "bye"
      },
      message: {
        reason: null,
        text: "Таслах хүсэлт ирлээ"
      },
      status: "success"
    }

    sendSocketMessage(JSON.stringify(msg), natsTopic.current, username);
    await roomClient.close();
  }

  if (!key) return <Error message="Key not found" />;
  if (socketError)
    return <Error display="Хүсэлт биелэх боломжгүй" message="Socket Connection Error" />;
  // if (error)
  //   return <Error message="Mic error" display="Та микрофоноо зөвшөөрнө үү" />;
  if (checkQuery.isLoading) return <Loading />;
  if (checkQuery.isError) return <Error message="API check api error" />;
  // if (checkQuery.data?.message === 'Too Many Requests')
  //   return (
  //     <Error display="Оролдлогын тоо дууслаа" message="API check api error" />
  //   );
  if (checkQuery.data.data.success === 'false')
    return <Error message="API check api response error" />;

  if (loginQuery.isLoading) return <Loading />;
  if (loginQuery.isError) return <Error message="API login error" />;
  // if (callMutation.isError) return <Error message="Call mutation error" />;

  if (step === 'number' && !isNumberDisabled) {
    return (
      <Grid
        container
        spacing={1}
        component="form"
        onSubmit={onNext}
        justifyContent="center"
        width={350}
      >
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography sx={{ textAlign: 'center' }}>
            Та өөрийн утасны дугаараа оруулна уу
          </Typography>

        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            value={code}
            options={codes}
            renderInput={(params) => (
              <TextField {...params} label="" size="small" />
            )}
            disableClearable
            onChange={(_e, num) => onCode(num)}
            noOptionsText="Алга"
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            // label={'Утасны дугаараа оруулна уу'}
            // placeholder="Дугаараа оруулна уу"
            fullWidth
            size="small"
            onChange={onNumber}
            value={number}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button
            endIcon={<NavigateNextIcon fontSize="small" />}
            variant="contained"
            disabled={!isValidPhoneNumber(code + number)}
            type="submit"
            sx={{ backgroundColor: '#0d0c54' }}
            fullWidth
          >
            Үргэлжлүүлэх
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (step === 'extension' || (step === 'number' && isNumberDisabled)) {
    return (
      <MenuList sx={{ width: '100%' }}>
        {checkQuery?.data?.data?.data?.shortcuts?.map((shortcut: any) => (
          <MenuItem
            key={shortcut.number}
            sx={{
              py: 2,
              backgroundColor: '#0d0c54',
              color: 'white',
              '&:hover': {
                backgroundColor: '#19186e',
              },
              border: '1px solid transparent',
              borderRadius: '12px',
              mb: 1,
            }}
            onClick={() => onStartCall(shortcut.number)}
          >
            <ListItemIcon>
              <CallIcon sx={{ fill: 'white' }} />
            </ListItemIcon>
            <ListItemText>{shortcut.name}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  if (step === 'ringing') {
    return (
      <Box sx={{ display: 'grid', placeItems: 'center' }}>
        <Typography sx={{ mb: 3 }}>Дуудаж байна{dot}</Typography>
        <RingVolumeIcon
          className={shake ? css.rotate : ''}
          sx={{ fontSize: 56, color: '#0d0c54', mb: 3 }}
        />

        <Button
          startIcon={<CallEndIcon />}
          onClick={onEndBeforeCall}
          color="error"
          variant="contained"
        >
          Дуудлага таслах
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <Typography
        sx={{
          fontSize: 32,
          textAlign: 'center',
          mb: 2,
        }}
      >
        {secondsToHms(seconds)}
      </Typography>
      <Button
        startIcon={<CallEndIcon />}
        onClick={onEndCall}
        color="error"
        variant="contained"
      >
        Дуудлага таслах
      </Button>
    </Box>
  );
}
